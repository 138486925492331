/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ActionsColumn from "@components/ActionsColumn";
import ContactTable from "@components/ContactTable";
import FileName from "@components/FileName";
import HeaderModule from "@components/HeaderModule";
import { IFile } from "@models/files/File.type";
import { IRow } from "@models/Table.type";
import { ITabsProps as ITab } from "@components/Tabs";
import PaginationLoader from "@components/PaginationLoader/PaginationLoader";
import PanelTabs from "@components/PanelTabs";
import PinModal from "@components/PinModal";
import { TIcon } from "@components/MaterialIcon";
import Table from "@components/Table";
import { TagsPopUp } from "@components/TagsPopUp";
import { addNextPageResults } from "@redux/search/slice";
import { createToast } from "@helpers/createToast";
import { formatDate } from "@helpers/formatDate";
import { list } from "../../app/FileManagementColumns";
import { trackInteractions } from "@redux/files/thunks/fileThunk";
import useAppDispatch from "@hooks/useAppDispatch";
import useAppSelector from "@hooks/useAppSelector";
import { useGetNextPage } from "@redux/search/api";
import { useShowDocument } from "@hooks/fetchs/useShowDocument";
import useWindowSize from "@hooks/useWindowSize";
import { usePinUnpinContext } from "../../providers/PinUnpinProvider";

const tabs: ITab[] = [
  { id: 0, title: "Documents" },
  { id: 1, title: "Contacts" },
  { id: 2, title: "Stores" },
  { id: 3, title: "Projects" },
];

const MainResultsSection: React.FC = () => {
  const { responsive } = useWindowSize();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { results } = useAppSelector((state) => state.search);
  const { getData } = useShowDocument();

  const [activeTab, setActiveTab] = useState<ITab>({
    id: 0,
    title: "Documents",
  });
  const [nextPageUrl, setNextPageUrl] = useState("");

  const { data: nextPageData, isFetching } = useGetNextPage(
    { query: nextPageUrl, tab: activeTab.title.toLowerCase() },
    { skip: !nextPageUrl },
  );

  const dispatch = useAppDispatch();

  const { isOpenPinModal, setIsOpenPinModal } = usePinUnpinContext();

  const handleToggleTab = (tab: ITab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setNextPageUrl("");
  }, [activeTab]);

  const hasResults = Object.entries(results).some(
    (value: any) => value[1]?.data.length > 0,
  );

  useEffect(() => {
    if (!hasResults) navigate("/dashboard");
  }, [hasResults, navigate]);

  useEffect(() => {
    if (!nextPageData) return;

    if (results[activeTab.title.toLowerCase()].links.next) {
      dispatch(
        addNextPageResults({
          type: activeTab.title.toLowerCase(),
          data: nextPageData,
        }),
      );
    }
  }, [nextPageData, dispatch]);

  const handleDownload = (id: string) => {
    getData(id).then((e) => {
      const { url } = e.data.file;
      const { name, type } = e.data;

      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = objectUrl;
          link.download = `${name}.${type}`;
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(objectUrl);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    });
  };

  const actionList = [
    {
      id: "download",
      icon: "file_download" as TIcon,
      text: "Download",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        handleDownload(`${selectId}`);
      },
    },
    {
      id: "copy-link",
      icon: "file_copy" as TIcon,
      text: "Copy Link",
      onClick: (file: IFile) => {
        const selectId = file?.id;
        if (selectId) {
          navigator.clipboard.writeText(
            `${window.location.origin}/document/${selectId}`,
          );
          createToast("Copied", "success", dispatch);
        }
        dispatch(trackInteractions({ data: [file.id], type: "share" }));
      },
    },
  ];

  const filesColumns = [
    {
      header: "Name",
      id: "name",
      colspan: { xs: "3" },
      field: "name",
      body: (file: IRow) => (
        <FileName
          name={file.name}
          type={file.type}
          deleted_at={file.deleted_at}
        />
      ),
    },
    {
      header: "Date",
      id: "created_at",
      colspan: { xs: "1" },
      field: "created_at",
      parsedString: ({ created_at }: IRow) => formatDate(created_at),
      fixedWidth: { xs: "100px", sm: "unset" },
    },
    {
      header: "Type",
      id: "type",
      colspan: { sm: "2" },
      field: "type",
      parsedString: ({ type }: IRow) => (type ? `.${type}` : "folder"),
      collapsable: true,
    },
    {
      header: "Tags",
      id: "tags",
      colspan: { xs: "1" },
      field: "",
      body: (file: IRow) => {
        const { tags } = file;
        return tags.length > 0 ? (
          <TagsPopUp tags={tags} />
        ) : (
          <span className="o-ft-sm-400 o-cl-grey-200">-</span>
        );
      },
      collapsable: true,
    },
    {
      header: "Actions",
      id: "actions",
      colspan: { xs: "1", sm: "3" },
      field: "",
      body: (file: IRow) => (
        <ActionsColumn
          actionList={actionList}
          {...{ file, list }}
          dotsShowLength={3}
          dotsShow={false}
        />
      ),
      hiddenMobileTitle: true,
      fixedWidth: { xs: "40px", lg: "212px" },
    },
  ];

  const storesColumns = [
    {
      header: "Name",
      id: "1",
      field: "name",
      body: (item: any) => (
        <FileName isGrayIcon name={item.name} icon="storefront" />
      ),
    },
    { header: "Store ID", id: "2", field: "store_number" },
    { header: "Address", id: "3", field: "address_1" },
    { header: "Owner User", id: "4", field: "owner_user.name" },
  ];

  const projectsColumns = [
    {
      header: "Name",
      id: "1",
      colspan: { xs: "3" },
      field: "name",
      body: (item: any) => (
        <FileName
          isGrayIcon
          name={item.name || item.code}
          icon="content_paste"
        />
      ),
    },
    {
      header: "City State",
      id: "2",
      colspan: { xs: "3" },
      field: "",
      body: (item: any) => (
        <span>
          {item.store?.city?.state?.name ?? ""},{" "}
          {item.store?.city?.state?.code ?? ""}
        </span>
      ),
    },
    {
      header: "Site ID",
      id: "2",
      colspan: { xs: "3" },
      field: "store.site_number",
    },
    { header: "Store ID", id: "2", colspan: { xs: "3" }, field: "store.id" },
  ];

  useEffect(() => {
    const tabParam = Number(searchParams.get("tab") || "");

    if (tabParam) {
      setActiveTab({
        id: tabParam,
        title: tabs.find((tab) => tab.id === tabParam)?.title || "",
      });
      return;
    }

    Object.entries(results).some(([key, value], index) => {
      if (value.data.length > 0) {
        setActiveTab({ id: index, title: key });
        return true;
      }
      return false;
    });
  }, [results, searchParams]);

  const switchTable = () => {
    const title = activeTab?.title.toLowerCase() || "";
    switch (activeTab?.id) {
      case 0:
        return (
          <Table
            dynamicRowClassName={() =>
              responsive.md ? "o-bg-grey-300:hover" : "o-bg-transparent"
            }
            columns={filesColumns}
            data={results[title].data || []}
            extraClassName={`${
              responsive.md ? "c-table--selectable mt-md-3" : ""
            }`}
          />
        );
      case 1:
        return (
          <div className="o-drag-border py-2 mt-md-3">
            <ContactTable data={results[title].data || []} />
          </div>
        );
      case 2:
        return (
          <Table
            dynamicRowClassName={() =>
              responsive.md ? "o-bg-grey-300:hover" : "o-bg-transparent"
            }
            columns={storesColumns}
            data={results[title].data || []}
            extraClassName={`${
              responsive.md ? "c-table--selectable mt-md-3" : ""
            }`}
          />
        );
      case 3:
        return (
          <Table
            dynamicRowClassName={() =>
              responsive.md ? "o-bg-grey-300:hover" : "o-bg-transparent"
            }
            columns={projectsColumns}
            data={results[title].data || []}
            extraClassName={`${
              responsive.md ? "c-table--selectable mt-md-3" : ""
            }`}
          />
        );
      default:
        return <div />;
    }
  };

  const hasMoreThanOneResult =
    Object.values(results).reduce((prev, curr: any) => {
      return curr.data.length > 0 ? prev + 1 : prev;
    }, 0) > 1;

  const handleScroll = (event: any) => {
    const { target } = event;
    if (
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1 &&
      target.scrollHeight - target.scrollTop >= target.clientHeight - 1
    ) {
      const nextPage = results[activeTab.title.toLowerCase()].links.next;
      if (nextPage) {
        setNextPageUrl(nextPage);
      }
    }
  };

  return (
    <div className="v-results o-screen-container" onScroll={handleScroll}>
      <HeaderModule title="Results" resultsView />

      <PinModal pinModal={isOpenPinModal} setPinModal={setIsOpenPinModal} />

      {hasMoreThanOneResult && (
        <div className="mt-5">
          <PanelTabs
            tabs={
              process.env.REACT_APP_ENABLED_STORE_MODULE &&
              results.stores.data.length > 0
                ? tabs
                : tabs.filter(
                    (tab) => tab.title !== "Projects" && tab.title !== "Stores",
                  )
            }
            activeTab={activeTab}
            onChange={handleToggleTab}
          />
        </div>
      )}

      {switchTable()}

      {isFetching && <PaginationLoader placeholder="Loading more results..." />}
    </div>
  );
};

export default MainResultsSection;
